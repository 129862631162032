import React from "react"
import PageHeader from "components/PageHeader"
import Seo from "components/Seo"
import SectionPrograms from "components/SectionPrograms"
import SectionWhyUs from "components/SectionWhyUs"
import SectionContactCta from "components/SectionContactCta"

const CASES = [
  {
    title: "조직체계 설계",
    description:
      "환경 전략, 업무프로세스, 인사제도, 구성원 및 조직문화 등 다양한 환경요인에 효율적으로 대처할 수 있는 조직구조 및 R&R를 설정합니다.",
  },

  {
    title: "인력생산성 진단",
    description:
      "기업의 인력생산성을 측정할 수 있는 지표를 개발하고, 인력생산성에 대한 계량적 평가 및 개선요인을 도출합니다.",
  },

  {
    title: "적정인력계획 수립",
    description:
      "기업전략 및 재무적 요인을 바탕으로 전사 적정인력규모 및 조직·직무별 인력과부족 또는 잉여수준을 예측한 후 HR Relocation을 통해 적정 수준의 인력을 확보합니다.",
  },

  {
    title: "HR Transformation",
    description:
      "전략 또는 조직체계의 효율적 변화에 필요한 HR Transformation 방향성을 확인하고, E2E Road Map 수립을 통해 성공적 변화관리를 도모합니다.",
  },

  {
    title: "조직문화 혁신",
    description:
      "현 조직문화의 주요 속성과 저해요인을 발굴하여 바람직한 조직문화 형성을 위한 효과적 프로그램 개발을 통해 조직문화 혁신을 도모합니다.",
  },

  {
    title: "변화관리 & 커뮤니케이션",
    description:
      "변화에 대한 구성원 수용성을 측정하고, 구성원의 의식 및 행동변화를 위한 변화관리&커뮤니케이션 전략 및 다양한 프로그램을 개발합니다.",
  },
]

export default function OrganizationProgramPage() {
  return (
    <div>
      <Seo
        title={"조직개발 (Organization Development"}
        description="OD는 외부환경 변화, 기업전략 및 핵심경쟁력을 연계하여 조직체계 및 인적자원에 대한 개발이 이루어져야 합니다."
      />
      <PageHeader
        title={"조직개발 (OD)"}
        description="OD는 외부환경 변화, 기업전략 및 핵심경쟁력을 연계하여 조직체계 및 인적자원에 대한 개발이 이루어져야 합니다."
      />

      <SectionPrograms source={CASES} />

      <SectionWhyUs />
      <SectionContactCta />
    </div>
  )
}
